import { Link } from "gatsby";
import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import * as styles from "./404.module.css";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 Not Found" />
    <div className={styles.container}>
      <p className={styles.text}>
        Something went wrong. Let's get you <Link href={`/`}>back home</Link>.
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
